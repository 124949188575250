<template>
  <unauthenticated-form>
    <form @submit.prevent="onSubmit">
      <BfInput
        v-model="email"
        label="Email Address"
        name="username"
        type="email"
        autofocus
      />
      <ValidationProvider
        v-slot="{ errors }"
        name="password"
        rules="required|password"
        mode="aggressive"
      >
        <BfPassword
          v-model="password"
          label="New Password*"
          autofocus
          :error="errors[0]"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        rules="required|sameAsPassword:@password"
      >
        <BfInput
          v-model="confirmPassword"
          label="Confirm Password*"
          name="confirm-password"
          type="password"
          :error="errors[0]"
        />
      </ValidationProvider>
      <el-alert
        v-if="error"
        type="error"
        :closable="false"
      >
        {{ error }}
      </el-alert>
      <BfInput
        type="submit"
        value="Submit"
      />
    </form>
  </unauthenticated-form>
</template>

<script>
import Auth from '@aws-amplify/auth'

import BfInput from '@/components/BfInput/BfInput'
import BfPassword from '@/components/BfPassword/BfPassword'
import UnauthenticatedForm from '@/components/UnauthenticatedForm/UnauthenticatedForm'
import { mapActions } from 'vuex'
import { logError } from '@/utils/logging'

export default {
  name: 'ForgotPassword',
  components: { BfInput, BfPassword, UnauthenticatedForm },
  data: () => ({
    email: '',
    password: '',
    confirmPassword: '',
    error: null
  }),
  methods: {
    ...mapActions(['flash']),
    async onSubmit(evt) {
      this.error = null
      await Auth.forgotPasswordSubmit(
        this.email,
        this.$route.query.code,
        this.password
      ).then(async (data) => {
        await Auth.signIn(this.email, this.password)
          .then(() => this.$router.push('/'))
          .catch(() => {
            this.flash('Your password has been updated.')
            this.$router.push({ name: 'login' })
          })
      }).catch(err => {
        switch (err.code) {
          case 'CodeMismatchException':
            this.error = 'Your email address and reset link don\'t match. Please try again.'
            break
          case 'ExpiredCodeException':
            this.error = 'Your password reset link has expired. Please request a new link.'
            break
          case 'LimitExceededException':
          case 'TooManyRequestsException':
            this.error = 'You have exceeded the limit for password resets. Please try again later.'
            break
          case 'TooManyFailedAttemptsException':
            this.error = 'You have exceeded the limit for failed password reset attempts. Please try again later.'
            break
          default:
            logError(err, 'ResetPassword.vue reset password')
            this.error = 'Failed to reset your password. Please try again.'
            break
        }
      })
    }
  }
}
</script>
